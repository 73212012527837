module.exports = function (module) {
  if (!module.webpackPolyfill) {
    module.deprecate = function () {};
    module.paths = [];
    // module.parent = undefined by default
    if (!module.children) module.children = [];
    Object.defineProperty(module, "loaded", {
      enumerable: true,
      get: function get() {
        return module.l;
      }
    });
    Object.defineProperty(module, "id", {
      enumerable: true,
      get: function get() {
        return module.i;
      }
    });
    module.webpackPolyfill = 1;
  }
  return module;
};
void function register() {
  /* react-hot-loader/webpack */var reactHotLoader = typeof reactHotLoaderGlobal !== 'undefined' ? reactHotLoaderGlobal["default"] : undefined;
  if (!reactHotLoader) {
    return;
  } /* eslint-disable camelcase, no-undef */
  var webpackExports = typeof __webpack_exports__ !== 'undefined' ? __webpack_exports__ : exports; /* eslint-enable camelcase, no-undef */
  if (!webpackExports) {
    return;
  }
  if (typeof webpackExports === 'function') {
    reactHotLoader.register(webpackExports, 'module.exports', "/home/runner/work/shoutout_site/shoutout_site/node_modules/webpack/buildin/module.js");
    return;
  } /* eslint-disable no-restricted-syntax */
  for (var key in webpackExports) {
    /* eslint-enable no-restricted-syntax */if (!Object.prototype.hasOwnProperty.call(webpackExports, key)) {
      continue;
    }
    var namedExport = void 0;
    try {
      namedExport = webpackExports[key];
    } catch (err) {
      continue;
    }
    reactHotLoader.register(namedExport, key, "/home/runner/work/shoutout_site/shoutout_site/node_modules/webpack/buildin/module.js");
  }
}();